import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UTILITY_TYPE } from '@/shared/constants'

import { AppState } from '..'

interface ISelectedBenefitDateType {
  selectedStartAt: string | null
  selectedEndAt: string | null
  selectedEntryTime: string | null
  selectedDisplayTime: string | null
}

interface ISelectedTitleType {
  title: string | null
  subTitle: string | null
  bottomSheetSubText: string | null
}
interface ICurrentUtilityValue {
  utilityDescription: string | null
  isDive: boolean | null
  utilityType: keyof typeof UTILITY_TYPE | null
}
export interface IClaimState {
  statusId: string | null
  itemId: string | null
  selectedBenefitDate: ISelectedBenefitDateType | null
  selectedName: string | null
  selectedTitle: ISelectedTitleType | null
  collectionAddress: string | null
  currentUtilityValue: ICurrentUtilityValue | null
  selectedVideoUrl: string | null
  EntryNumber?: number | null
  directionalMap: string | null
  EntryNumberDisplayedAt: string | null
}

export const initialState: IClaimState = {
  currentUtilityValue: null,
  statusId: null,
  selectedName: null,
  itemId: null,
  EntryNumber: null,
  selectedBenefitDate: {
    selectedStartAt: null,
    selectedEndAt: null,
    selectedEntryTime: null,
    selectedDisplayTime: null,
  },
  selectedTitle: {
    title: null,
    subTitle: null,
    bottomSheetSubText: null,
  },
  collectionAddress: null,
  selectedVideoUrl: null,
  directionalMap: null,
  EntryNumberDisplayedAt: null,
}

export const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    resetBenefitState: state => {
      state.currentUtilityValue = null
      state.itemId = null
      state.statusId = null
      state.selectedBenefitDate = null
      state.selectedTitle = null
      state.selectedName = null
      state.collectionAddress = null
      state.EntryNumberDisplayedAt = null
    },
    setCurrentUtilityValue: (state, action: PayloadAction<ICurrentUtilityValue | null>) => {
      state.currentUtilityValue = action.payload
    },
    setSelectedName: (state, action: PayloadAction<string | null>) => {
      state.selectedName = action.payload
    },

    setStatusId: (state, action: PayloadAction<string | null>) => {
      state.statusId = action.payload
    },
    setItemId: (state, action: PayloadAction<string | null>) => {
      state.itemId = action.payload
    },
    setSelectedBenefitDate: (state, action: PayloadAction<ISelectedBenefitDateType | null>) => {
      state.selectedBenefitDate = action.payload
    },
    setSelectedTitle: (state, action: PayloadAction<ISelectedTitleType | null>) => {
      state.selectedTitle = action.payload
    },
    setCollectionAddress: (state, action: PayloadAction<string | null>) => {
      state.collectionAddress = action.payload
    },
    setSelectedVideoUrl: (state, action: PayloadAction<string | null>) => {
      state.selectedVideoUrl = action.payload
    },
    setSelectedEntryNumber: (state, action: PayloadAction<number | null>) => {
      state.EntryNumber = action.payload
    },
    setSelectedDirectionalMap: (state, action: PayloadAction<string | null>) => {
      state.directionalMap = action.payload
    },
    setEntryNumberDisplayedAt: (state, action: PayloadAction<string | null>) => {
      state.EntryNumberDisplayedAt = action.payload
    },
  },
})

export const selectCurrentUtilityValue = (state: AppState) => state.claim.currentUtilityValue
export const selectedSelectedName = (state: AppState) => state.claim.selectedName
export const selectCollectionAddress = (state: AppState) => state.claim.collectionAddress
export const selectSelectedBenefit = (state: AppState) => {
  return {
    selectedBenefitDate: state.claim.selectedBenefitDate,
    selectedTitle: state.claim.selectedTitle,
    statusId: state.claim.statusId,
    itemId: state.claim.itemId,
    selectedDirectionalMap: state.claim.directionalMap,
    EntryNumberDisplayedAt: state.claim.EntryNumberDisplayedAt,
  }
}
export const selectSelectedVideoUrl = (state: AppState) => state.claim.selectedVideoUrl
export const selectSelectedEntryNumber = (state: AppState) => state.claim.EntryNumber

export const {
  setCurrentUtilityValue,
  setStatusId,
  setItemId,
  setSelectedTitle,
  setSelectedName,
  setCollectionAddress,
  setSelectedBenefitDate,
  setSelectedVideoUrl,
  setSelectedEntryNumber,
  setSelectedDirectionalMap,
  setEntryNumberDisplayedAt,
} = claimSlice.actions

export default claimSlice
