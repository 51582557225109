/* eslint-disable @next/next/no-img-element */
import dayjs from 'dayjs'
import { i18n, useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import QRCode from 'react-qr-code'

import { useGetQrValue } from '@/shared/hooks/useGetQrValue'
import { selectSelectedBenefit, selectSelectedEntryNumber } from '@/store/claim/claimSlice'
import { useAppSelector } from '@/store/hooks'

import { Spinner } from './core-ui'

const QRCodeSection = () => {
  const { t } = useTranslation(['web-claim', 'common'])
  const locales = i18n?.language ?? 'ko'
  dayjs.locale(locales)

  const selectedBenefit = useAppSelector(selectSelectedBenefit)
  const entryNumber = useAppSelector(selectSelectedEntryNumber)

  const { selectedTitle, selectedBenefitDate, statusId, EntryNumberDisplayedAt } = selectedBenefit

  const { count, qrValue, isCanEntrance, isCanDisplayedEntryNumber } = useGetQrValue({
    statusId,
    entryTime: selectedBenefitDate?.selectedEntryTime,
    EntryNumberDisplayedAt,
  })

  const entryTime = useMemo(() => {
    if (selectedBenefitDate === null || selectedBenefitDate.selectedEntryTime === null) return

    if (
      dayjs(selectedBenefitDate.selectedEntryTime).format('A') === '오후' &&
      dayjs(selectedBenefitDate.selectedEntryTime).hour() === 12
    ) {
      return dayjs(selectedBenefitDate.selectedEntryTime).format('M/D(ddd) 낮 h:mm')
    }

    return dayjs(selectedBenefitDate.selectedEntryTime).format('M/D(ddd) A h:mm')
  }, [selectedBenefitDate])

  const RenderQRCode = useCallback(() => {
    if (!isCanEntrance)
      return (
        <div className="w-[240px] h-[240px] rounded-2xl bg-[#484848] flex flex-col justify-center items-center">
          <p className="whitespace-pre-line text-lg font-medium leading-[27px] text-[#F9F9F9] text-center">
            {t('web-claim:claim.qr-bottom-modal-lable-before-use-time')}
          </p>
        </div>
      )

    if (isCanEntrance && qrValue)
      return (
        <div className="text-sm text-textSecondary bg-white rounded-2xl flex flex-col gap-[22px] p-[20px] items-center justify-center">
          <QRCode style={{ height: '200px', width: '200px' }} value={qrValue} />
        </div>
      )
    else
      return (
        <div className="text-sm text-textSecondary bg-white rounded-2xl flex flex-col gap-[22px] p-[20px] items-center justify-center">
          <div className="w-[200px] h-[200px] bg-white flex items-center justify-center">
            <Spinner />
          </div>
        </div>
      )
  }, [isCanEntrance, qrValue, t])

  const RenderNextRefreshTimeAndText = useCallback(() => {
    const text = t('web-claim:web-claim.label-display_refresh')

    return (
      <p className="text-textSecondary text-sm">
        {locales === 'ko' ? (
          <>
            {text.replace('{value}초', '')}
            <span style={{ color: 'white', fontSize: '15px' }}>{count}초</span>
          </>
        ) : (
          <>
            {text.replace('{value} seconds', '')}{' '}
            <span style={{ color: 'white', fontSize: '15px' }}>{count} seconds</span>
          </>
        )}
      </p>
    )
  }, [count, locales, t])

  return (
    <div className="h-[100vh] flex flex-col justify-between items-center">
      <div>
        <div className="h-[64px] flex justify-center items-center text-center">
          <div className="text-lg leading-normal font-medium">
            {t('web-claim:web-claim.label-entry_qr')}
          </div>
        </div>
        <div className="h-[600px] overflow-y-auto scrollbar-hide mt-6">
          <div className="flex flex-col gap-2 items-center justify-center w-full text-center break-keep">
            <div className="flex flex-col items-center">
              <div className="w-full text-xl font-semibold text-center overflow-hidden text-ellipsis line-clamp-2">
                {selectedTitle?.title}
              </div>
              <div className="w-full text-lg font-medium text-center overflow-hidden text-ellipsis line-clamp-2 mt-1">
                {selectedTitle?.subTitle}
              </div>
            </div>
            {entryTime && (
              <div className="text-textAccent text-sm mt-1">
                {selectedTitle?.bottomSheetSubText}
              </div>
            )}
          </div>
          <div className="mt-10 w-full">
            <div className="gap-8 w-full items-center justify-center flex">
              <RenderQRCode />
            </div>
          </div>
          {isCanEntrance && (
            <div className="flex w-full items-center justify-center gap-2 mt-[12px]">
              <RenderNextRefreshTimeAndText />
            </div>
          )}
          {isCanDisplayedEntryNumber && (
            <div className="max-w-[350px] line-clamp-2 text-lg font-medium flex items-center justify-center mt-[18px]">
              <div className="flex items-center justify-center gap-2 min-w-[117px] bg-appBgQuaternary px-[12px] py-[6.5px] rounded-md">
                <p> {t('web-claim:web-claim.label-qr-bottom-modal')} </p>
                <p>{entryNumber}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="max-w-[343px] mb-[24px] bg-bgTertiary flex justify-center items-center gap-1 rounded-lg text-sm p-4 w-full text-appTextSecondary">
        <div>{t('web-claim:claim.qr-bottom-modal-lable-on-use-time')}</div>
      </div>
    </div>
  )
}

export default QRCodeSection
