import dayjs from 'dayjs'
import { useCallback } from 'react'

import {
  setEntryNumberDisplayedAt,
  setItemId,
  setSelectedBenefitDate,
  setSelectedDirectionalMap,
  setSelectedEntryNumber,
  setSelectedTitle,
  setSelectedVideoUrl,
  setStatusId,
} from '@/store/claim/claimSlice'
import { useAppDispatch } from '@/store/hooks'

import { ManagedDialog, USE_TYPE } from '../constants'
import { IBenefits } from '../types'
import { useDialog } from './useDialog'

interface IUseBenefitPropsType {
  utility: IBenefits
  useType?: keyof typeof USE_TYPE
  title: string | null
  subTitle: string | null
  itemId: string
  bottomSheetSubText: string
  EntryNumber?: number
}

export const useBenefit = ({
  utility,
  useType,
  title,
  subTitle,
  bottomSheetSubText,
  itemId,
  EntryNumber,
}: IUseBenefitPropsType) => {
  const dispatch = useAppDispatch()
  const { setDialog } = useDialog()

  const handleUseCommonBenefit = useCallback(() => {
    dispatch(
      setSelectedBenefitDate({
        selectedEntryTime: utility.EntryTime,
        selectedStartAt: `${dayjs(utility.StartAt)}`,
        selectedEndAt: `${dayjs(utility.EndAt)}`,
        selectedDisplayTime: utility.DisplayTime,
      }),
    )
    setDialog(ManagedDialog.confirmUseBenefits)

    dispatch(setItemId(itemId ?? null))
    dispatch(setStatusId(utility.StatusId))
    dispatch(setSelectedTitle({ title, subTitle, bottomSheetSubText }))
    dispatch(setSelectedDirectionalMap(utility.DirectionalMap ?? null))
  }, [
    bottomSheetSubText,
    dispatch,
    itemId,
    setDialog,
    subTitle,
    title,
    utility.DirectionalMap,
    utility.DisplayTime,
    utility.EndAt,
    utility.EntryTime,
    utility.StartAt,
    utility.StatusId,
  ])

  const handleUseQRBenefit = useCallback(() => {
    dispatch(
      setSelectedBenefitDate({
        selectedEntryTime: utility.EntryTime,
        selectedStartAt: `${dayjs(utility.StartAt)}`,
        selectedEndAt: `${dayjs(utility.EndAt)}`,
        selectedDisplayTime: utility.DisplayTime,
      }),
    )
    setDialog(ManagedDialog.claimUseQRBenefits)

    dispatch(setItemId(itemId ?? null))
    dispatch(setStatusId(utility.StatusId))
    dispatch(setSelectedTitle({ title, subTitle, bottomSheetSubText }))
    dispatch(setSelectedEntryNumber(utility.EntryNumber ?? null))
    dispatch(setEntryNumberDisplayedAt(utility.EntryNumberDisplayedAt ?? null))
  }, [
    bottomSheetSubText,
    dispatch,
    itemId,
    setDialog,
    subTitle,
    title,
    utility.DisplayTime,
    utility.EndAt,
    utility.EntryNumber,
    utility.EntryNumberDisplayedAt,
    utility.EntryTime,
    utility.StartAt,
    utility.StatusId,
  ])

  const handleUseVideoBenefit = useCallback(() => {
    dispatch(
      setSelectedBenefitDate({
        selectedEntryTime: utility.EntryTime,
        selectedStartAt: `${dayjs(utility.StartAt)}`,
        selectedEndAt: `${dayjs(utility.EndAt)}`,
        selectedDisplayTime: utility.DisplayTime,
      }),
    )
    dispatch(setSelectedTitle({ title, subTitle, bottomSheetSubText }))
    dispatch(setSelectedVideoUrl(utility.VideoUrl))
    setDialog(ManagedDialog.claimUseVideoBenefits)
  }, [
    bottomSheetSubText,
    dispatch,
    setDialog,
    subTitle,
    title,
    utility.DisplayTime,
    utility.EndAt,
    utility.EntryTime,
    utility.StartAt,
    utility.VideoUrl,
  ])

  const useBenefitMappings = {
    [USE_TYPE.COMMON]: handleUseCommonBenefit,
    [USE_TYPE.QR]: handleUseQRBenefit,
    [USE_TYPE.VIDEO]: handleUseVideoBenefit,
  }

  return { handleBenefitFunc: useType !== undefined ? useBenefitMappings[useType] : null }
}
