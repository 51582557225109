import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export const useGetQrValue = ({
  statusId,
  isOpened,
  entryTime,
  EntryNumberDisplayedAt,
}: {
  statusId: string | null
  isOpened?: boolean
  entryTime?: string | null
  EntryNumberDisplayedAt?: string | null
}) => {
  const INIT_QR_COUNT_VALUE = 30
  const [count, setCount] = useState(INIT_QR_COUNT_VALUE)
  const [qrValue, setQrValue] = useState<string | null>(null)
  const timer = useRef<NodeJS.Timer>()

  const createQrValue = useCallback(() => {
    return `${statusId}|${new Date().getTime()}`
  }, [statusId])

  useEffect(() => {
    setQrValue(createQrValue())
  }, [createQrValue])

  const isCanEntrance = useMemo(() => {
    if (!entryTime) return
    const now = new Date().getTime()
    const dDay = new Date(entryTime).getTime()
    const distance = dDay - now
    return distance < 0
  }, [entryTime])

  const isCanDisplayedEntryNumber = useMemo(() => {
    if (!EntryNumberDisplayedAt) return
    const now = new Date().getTime()
    const dDay = new Date(EntryNumberDisplayedAt).getTime()
    const distance = dDay - now
    return distance < 0
  }, [EntryNumberDisplayedAt])

  useEffect(() => {
    timer.current = setInterval(() => {
      if (count <= 1) {
        setQrValue(createQrValue())
        setCount(INIT_QR_COUNT_VALUE)
        return
      }
      setCount(count - 1)
    }, 1000)

    return () => clearInterval(timer.current)
  }, [count, createQrValue, isOpened, statusId])

  return {
    count,
    qrValue,
    isCanEntrance,
    isCanDisplayedEntryNumber,
  }
}
